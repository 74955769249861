import type { AppProps } from "next/app";
import "../styles/roots.css";
import "../styles/globals.css";
import "../assets/js/modals/modals.css";

// Time Since dependencies
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";
import { Provider } from "react-redux";
import store from "../store/store";
TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);
// End Time Since dependencies

export default function App({ Component, pageProps }: AppProps) {
	return (
		<Provider store={store}>
			<Component {...pageProps} />
		</Provider>
	);
}
